import PendingIcon from 'assets/icons/PendingIcon.svg'
import React, { FC } from 'react'
import { colors } from 'utils/mui'
import { useStyles } from './styles'

interface Props {
  pendingCount: number
  isActive: boolean
  onClick: () => void
}

const PendingButton: FC<Props> = ({ pendingCount, onClick }: Props) => {
  const { loaderStyles, buttonStyle } = useStyles()

  return (
    <div onClick={onClick} className={buttonStyle}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <img className={loaderStyles} src={PendingIcon} alt="pending-icon" />
        <span style={{ color: colors.proposalCardEndsColor }}>{`${pendingCount} Pending`}</span>
      </div>
    </div>
  )
}

export default PendingButton
