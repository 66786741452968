import { IconButton } from '@material-ui/core'
import CopyIcon from 'assets/icons/CopyIcon.svg'
import { PrimaryButton } from 'components/buttons'
import { ModalWrapper } from 'components/modal_wrapper'
import React, { useEffect } from 'react'
import { transformAddress } from 'utils/NumberFormatter'
import { Transaction } from 'utils/store'
import { useStyles } from './style'

type ModalProps = {
  handleClose: () => void
  open: boolean
  transactions: Transaction[]
  setTransactions: React.Dispatch<React.SetStateAction<Transaction[]>>
}

export const TransactionModal = ({ handleClose, open, transactions, setTransactions }: ModalProps) => {
  const { addressBox, addressText, modalBox } = useStyles()

  const copyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content)
  }
  const pendingTransactions = transactions.filter((el) => el.status === 'pending')

  const emptyTransactionsArray = pendingTransactions.length === 0 || transactions.length === 0
  const clearTransactions = () => {
    localStorage.removeItem('TRANSACTIONS')
    localStorage.removeItem('value')
    setTransactions([])
  }

  useEffect(() => {
    emptyTransactionsArray && handleClose()
  }, [pendingTransactions, transactions])

  return (
    <ModalWrapper open={open} handleClose={handleClose} titleText={'Pending Transactions:'}>
      <div style={{ padding: '20px 0' }}>
        {pendingTransactions.length > 0 && (
          <>
            <div className={modalBox}>
              {pendingTransactions.map((el, index) => {
                return (
                  <div key={index} className={addressBox} onClick={() => copyToClipboard(el.hash)}>
                    <span className={addressText}>{transformAddress(el.hash)}</span>
                    <IconButton>
                      <img src={CopyIcon} />
                    </IconButton>
                  </div>
                )
              })}
            </div>
            <PrimaryButton title={'toasts_clear_all_text'.localized()} onClick={clearTransactions} />
          </>
        )}
      </div>
    </ModalWrapper>
  )
}
