import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  loaderStyles: {
    width: '18px',
    height: '18px',
    WebkitAnimationName: 'spin',
    marginRight: '10px',
    WebkitAnimationDuration: '4000ms',
    WebkitAnimationIterationCount: 'infinite',
    WebkitAnimationTimingFunction: 'linear',
    MozAnimationName: 'spin',
    MozAnimationDuration: '4000ms',
    MozAnimationIterationCount: 'infinite',
    MozAnimationTimingFunction: 'linear',
    animationName: 'spin',
    animationDuration: '4000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
  buttonStyle: {
    backgroundColor: colors.primaryGreen,
    width: '135px',
    borderRadius: '10px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
})
