import { Button, makeStyles } from '@material-ui/core'
import React from 'react'
import { colors } from 'utils/mui'

const useStyles = makeStyles({
  buttonStyles: {
    backgroundColor: colors.claimButtonBg,
    padding: '5.5px 14px',
    color: colors.claimButtonTextColor,
    borderRadius: '26px',
    lineHeight: '20px',
    fontSize: '1rem',
    textTransform: 'none',
    fontWeight: 600,
    cursor: 'pointer',
    transition: 'all 0.4s',
    '&:hover': {
      backgroundColor: colors.claimButtonBg,
      opacity: 0.6,
    },
    '&:active': {
      backgroundColor: colors.claimButtonBg,
      opacity: 0.3,
    },
  },
})

interface ButtonProps {
  title: string
  disabled?: boolean
  onClick?: () => void
}

const RoundedButton = ({ title, onClick, disabled }: ButtonProps) => {
  const classes = useStyles()
  return (
    <Button disabled={disabled} className={classes.buttonStyles} onClick={onClick}>
      {title}
    </Button>
  )
}

export default RoundedButton
