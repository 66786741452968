import { Button, makeStyles, ButtonProps } from '@material-ui/core'
import React from 'react'
import { colors } from 'utils/mui'

const useStyles = makeStyles({
  buttonStyles: {
    backgroundColor: colors.primaryGreen,
    width: '100%',
    padding: '14px 0',
    color: colors.claimButtonTextColor,
    borderRadius: '6px',
    lineHeight: '19px',
    fontSize: '1rem',
    textTransform: 'none',
    fontWeight: 500,
    fontFamily: 'Rubik, sans-serif',
    cursor: 'pointer',
    transition: 'all 0.4s',
    '&:hover': {
      backgroundColor: colors.primaryGreen,
      opacity: 0.6,
    },
    '&:active': {
      backgroundColor: colors.primaryGreen,
      opacity: 0.3,
    },
  },
})

interface PrimaryButtonProps extends ButtonProps {
  title: string
  onClick?: () => void
  disabled?: boolean
}

const PrimaryButton = ({ title, onClick, disabled, children }: PrimaryButtonProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Button disabled={disabled} className={classes.buttonStyles} onClick={onClick}>
      {children}
      {title}
    </Button>
  )
}

export default PrimaryButton
