import HackenIcon from 'assets/icons/HackenFoundationLogo.svg'
import BridgeIcon from 'assets/icons/TwoArrowsIcon.svg'
import { PendingButton, WalletButton, WalletTypes } from 'components/buttons'
import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { BRIDGE } from 'routes'
import { Transaction } from 'utils/store'
import { useStyles } from './styles'

interface HeaderProps {
  walletType: WalletTypes
  activeWeb3Account: string
  activeThorAccount: string
  activateThorAccount: () => void
  activateWeb3Account: () => void
  transactions: Transaction[]
  openModal: () => void
}

export const Header: FC<HeaderProps> = ({
  walletType,
  activeWeb3Account,
  activeThorAccount,
  activateThorAccount,
  activateWeb3Account,
  transactions,
  openModal,
}: HeaderProps): JSX.Element => {
  const {
    wrap,
    logoContainer,
    navLinkContairer,
    navLinkActiveContairer,
    navBarContainer,
    ulList,
    buttonContainer,
  } = useStyles()

  const transactionCount = transactions.filter((item: Transaction) => item.status === 'pending').length
  return (
    <header className={wrap}>
      <div style={{ width: transactionCount === 0 ? '330px' : '465px' }} className={logoContainer}>
        <img style={{ height: '50px' }} src={HackenIcon} />
      </div>
      <nav className={navBarContainer}>
        <ul className={ulList}>
          {/* <li style={{ marginRight: '30px' }}>
            <NavLink to={VOTING} className={navLinkContairer} activeClassName={navLinkActiveContairer}>
              <span>{'common_voting_page_name'.localized()}</span>
              <div>
                <img style={{ padding: '2px 5px' }} src={EthLogo} />
              </div>
            </NavLink>
          </li>
          <li style={{ marginRight: '30px' }}>
            <NavLink exact to={STAKING} className={navLinkContairer} activeClassName={navLinkActiveContairer}>
              <span>{'common_staking_page_name'.localized()}</span>
              <div>
                <img style={{ padding: '4.5px 4px' }} src={VetLogo} />
              </div>
            </NavLink>
          </li> */}
          <li>
            <NavLink exact to={BRIDGE} className={navLinkContairer} activeClassName={navLinkActiveContairer}>
              <span>{'common_claim_page_name'.localized()}</span>
              <div>
                <img style={{ padding: '3.5px 4px', width: '14px' }} src={BridgeIcon} />
              </div>
            </NavLink>
          </li>
        </ul>
      </nav>

      <div style={{ width: transactionCount === 0 ? '330px' : '465px' }} className={buttonContainer}>
        {transactionCount !== 0 && (
          <PendingButton pendingCount={transactionCount} isActive={transactionCount !== 0} onClick={openModal} />
        )}
        <WalletButton
          activateThorAccount={activateThorAccount}
          activateWeb3Account={activateWeb3Account}
          activeThorAccount={activeThorAccount}
          activeWeb3Account={activeWeb3Account}
          walletType={walletType}
        />
      </div>
    </header>
  )
}
