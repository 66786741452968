import React, { FC, ReactElement } from 'react'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import closeModalBtn from 'assets/icons/close.svg'
import PropTypes from 'prop-types'
import { colors } from 'utils/mui'

const useStyles = makeStyles({
  root: {
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 15,
    cursor: 'pointer',
  },
  paperWidthSm: {
    background: colors.modalBg,
    boxShadow: colors.modalBoxShadow,
    borderRadius: 10,
    padding: 16,
    minWidth: 425,
  },
  title: {
    fontSize: '1.25rem',
    lineHeight: '24px',
    color: colors.white,
  },
  dialogContent: {
    padding: 0,
    overflowY: 'initial',
  },
})

type ModalWindowWrapperProps = {
  handleClose: () => void
  open: boolean
  titleText: JSX.Element | string
  children: JSX.Element
}

export const ModalWrapper = ({ handleClose, open, children, titleText }: ModalWindowWrapperProps): JSX.Element => {
  const { paperWidthSm, closeButton, root, title, dialogContent } = useStyles()
  return (
    <Dialog classes={{ paperWidthSm: paperWidthSm }} maxWidth="sm" onClose={handleClose} open={open}>
      <MuiDialogTitle disableTypography className={root}>
        <Typography classes={{ root: title }}>{titleText}</Typography>
        <img onClick={handleClose} className={closeButton} src={closeModalBtn} alt="closeModal" />
      </MuiDialogTitle>
      <MuiDialogContent classes={{ root: dialogContent }}>{children}</MuiDialogContent>
    </Dialog>
  )
}
