import { ThemeOptions } from '@material-ui/core'

export const colors: { [key: string]: string } = {
  primaryGreen: '#85FFC7',
  globalBlack: '#1A1A1A',
  primaryGrayTextColor: '#666666',
  primaryGrayTextActiveColor: '#E5E5E5',
  navBarIconBg: '#3B3B3B',
  navBarActiveIconBg: '#616161',
  walletButtonTextColor: '#CCCCCC',
  headerSeparator: '#333333',
  white: '#FFFFFF',
  votingCategoryTitleColor: '#C2C2C2',
  stakingTitle: '#CCCCCC',
  titleColorH2: '#666666',
  modalBg: '#262626',
  sliderColor: '#333333',
  inputAdornmentColor: '#B3B3B3',
  //Card colors
  proposalCardBg: '#262626',
  proposalCardBorderColor: 'rgba(133, 255, 199, 0.5)',
  proposalCardEndsColor: '#808080',
  proposalTextColor: '#B2B2B2',
  claimCardBorderColor: '#4D4D4D',
  claimCardValuesColor: '#D9D9D9',
  claimButtonBg: '#FBD1A2',
  claimButtonTextColor: '#19191A',
  optionCardBg: '#0F0F0F',
  //shadows
  modalBoxShadow: '0px 0px 25px rgba(0, 0, 0, 0.5)',
}
export const color = (c: string): string => colors[c]
export const buildVariant = (fontWeight: number, fontSize: number, color: string): unknown => ({
  fontWeight,
  fontSize,
  color: colors[color],
})

export const themeConfig: ThemeOptions | undefined = {
  typography: {
    h2: {
      fontSize: 14,
      lineHeight: '17px',
      color: colors.titleColorH2,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: '8px 12px',
        color: '#fff',
        fontSize: 24,
        lineHeight: '28px',
      },
      notchedOutline: {
        border: 'none',
      },
    },
  },
}
