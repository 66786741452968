import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '70px',
    padding: '0 30px',
    margin: 'auto',
    borderBottom: `2px solid ${colors.headerSeparator}`,
    backgroundColor: colors.globalBlack,
    userSelect: 'none',
    '@media (max-width: 575px)': {
      height: '225px',
      flexDirection: 'column',
      padding: '20px',
      boxSizing: 'border-box',
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 575px)': {
      width: 'auto !important',
    },
  },
  navBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  ulList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },
  navLinkContairer: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    transition: 'all 0.4s',
    textDecoration: 'none',
    '&>span': {
      color: colors.primaryGrayTextColor,
      marginRight: '6px',
      fontSize: '1.13rem',
    },
    '&>div': {
      backgroundColor: colors.navBarIconBg,
      height: 21,
      width: 21,
      borderRadius: '50%',
    },
    '&:hover': {
      opacity: 0.6,
    },
  },
  navLinkActiveContairer: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    transition: 'all 0.4s',
    textDecoration: 'none',
    '&>span': {
      color: colors.primaryGrayTextActiveColor,
      marginRight: '6px',
      fontSize: '1.13rem',
      transition: 'all 0.4s',
    },
    '&>div': {
      backgroundColor: colors.navBarActiveIconBg,
      height: 21,
      width: 21,
      borderRadius: '50%',
    },
    '&:hover': {
      opacity: 0.8,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 575px)': {
      width: 'auto !important',
    },
  },
})
