import gql from 'graphql-tag'

export const GET_PROPOSALS = gql`
  query Proposals {
    proposalAmounts {
      id
      amount
    }
    createdProposals {
      id
      title
      deadlineBlock
    }
  }
`
