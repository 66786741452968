import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import Big from 'big.js'
import { abiBridge } from 'contracts/web3'
import { AbiBridge } from 'contracts/web3/typings/abi_bridge'
import { useContext, useEffect } from 'react'
import { VPT_PRECISION } from 'utils/NumberFormatter'
import { BlockInfo, StoreContext, Transaction } from 'utils/store'
import { Provider } from './Provider'
import { useRefresh } from './RefreshContext'

export function shouldCheck(lastBlockNumber: BlockInfo, tx: Transaction): boolean {
  if (!tx.lastCheckedBlockNumber || !lastBlockNumber.data[tx.chain]) return true
  const activeChainIndex = lastBlockNumber.data[tx.chain].findIndex(
    (el: { chainId: number; blockNumber: number }) => el.chainId === tx.chainId,
  )

  if (activeChainIndex < 0) return false

  const blocksSinceCheck = lastBlockNumber.data[tx.chain][activeChainIndex].blockNumber - tx.lastCheckedBlockNumber
  if (blocksSinceCheck < 1) return false
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9
  } else if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2
  } else {
    // otherwise every block
    return true
  }
}

export default function TransactionUpdater(): null {
  const { chainId, account, library } = useWeb3ReactCore()
  const {
    transactions: [transactions, updateTransactions],
    blockInfo: [blockNumberInfo],
    updateEthBalances: updateEthBalances,
    updateVechainBalances: updateVechainBalances,
    hapi: [hapiBalance, setHapiBalance],
    metamask: [web3Account, setWeb3Account],
    withdrawable: [withdrawableBalance, setWithdrawableBalance],
  } = useContext(StoreContext)

  const { fastRefresh } = useRefresh()

  useEffect(() => {
    async function updateTransactionsStatuses() {
      const activeTransactions: Transaction[] = transactions
        .filter((el: Transaction) => el.status === 'pending')
        .filter((el: Transaction) => shouldCheck(blockNumberInfo, el))

      if (activeTransactions.length) {
        activeTransactions.map(async (tx: Transaction) => {
          if (tx.chain === 'eth') {
            const activeChainIndex = blockNumberInfo.data.eth.findIndex(
              (el: { chainId: number; blockNumber: number }) => el.chainId === tx.chainId,
            )
            if (!chainId || !library || !blockNumberInfo.data.eth || activeChainIndex < 0) return

            tx.lastCheckedBlockNumber = blockNumberInfo.data.eth[activeChainIndex].blockNumber
            const receipt = await library.getTransactionReceipt(tx.hash)

            if (receipt) {
              tx.receipt = receipt
              tx.status = receipt.status ? 'success' : 'failed'
              updateEthBalances(account, library)
            }
            updateTransactions(tx)
          } else if (tx.chain === 'vechain' && blockNumberInfo.data.vechain) {
            const receipt = await connex.thor.transaction(tx.hash).get()
            if (receipt) {
              tx.receipt = receipt
              tx.status = 'success'
              updateTransactions(tx)
              updateVechainBalances()
            }
          }
        })
      }
      if (web3Account) {
        fetch(`https://hapi.hackenfoundation.com/api/receive/${web3Account}`)
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            setHapiBalance(new Big(data.reward as string).div(VPT_PRECISION).toFixed())
          })
        if (!library) return
        const provider: Provider = new Provider(library.provider)
        if (provider && provider.contractSet && web3Account) {
          const contractBridge = (new provider.eth.Contract(abiBridge, provider.contractSet.bridge) as any) as AbiBridge

          const balance = await contractBridge.methods
            .withdrawableAmountOf(web3Account)
            .call()
            .catch((e) => {
              throw new Error(`withdrawableBalance failed ${e.message}`)
            })
          setWithdrawableBalance(new Big(balance).div(VPT_PRECISION).toFixed())
        }
        const value = localStorage.getItem('value')
        if (withdrawableBalance && value !== null && value !== withdrawableBalance) {
          localStorage.removeItem('value')
        }
      }
    }
    updateTransactionsStatuses()
  }, [fastRefresh, chainId, library])
  return null
}
