import BN from 'bn.js'
import Big from 'big.js'

export enum TokenTypes {
  HAI,
  VPT,
}

export const HAI_PRECISION = 1e8
export const VPT_PRECISION = 1e18

export const votesFormatter = (amount: string) => new Big(amount).div(VPT_PRECISION).toFixed()

export class NumberFormatter {
  static ranges = [
    { divider: 1e18, suffix: 'E' },
    { divider: 1e15, suffix: 'P' },
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'G' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'K' },
  ]
  static prefixFormat(number: number): string {
    for (let i = 0; i < NumberFormatter.ranges.length; i++) {
      if (Math.abs(number) >= NumberFormatter.ranges[i].divider) {
        return (number / NumberFormatter.ranges[i].divider).toFixed(1) + NumberFormatter.ranges[i].suffix
      }
    }
    return number.toString()
  }
  static formatNumber(number: number | string, type: TokenTypes): number {
    return Number(number) / Math.pow(10, type === TokenTypes.HAI ? 8 : 18)
  }

  static formatBN(number: BN, type: TokenTypes): BN {
    return number.div(new BN(10).pow(new BN(type === TokenTypes.HAI ? 8 : 18)))
  }
}

export const transformAddress = (address: string) => {
  return `${address.substring(0, 6)}…${address.substring(address.length - 4)}`
}

declare module 'big.js' {
  interface Big {
    toFixedSpecial(n: number): string
    toFormat(n: Big, precision: number): string
  }
}

Big.prototype.toFixedSpecial = function (n: number) {
  let str = this.toFixed(n)
  if (str.indexOf('e+') === -1) return str

  // if number is in scientific notation, pick (b)ase and (p)ower
  str = str
    .replace('.', '')
    .split('e+')
    .reduce(function (p: string, b: number) {
      return p + Array(b - p.length + 2).join('0')
    })

  if (n > 0) str += '.' + Array(n + 1).join('0')

  return str
}

Big.prototype.toFormat = (n: Big, precision: number) => {
  const newNumber = new Big(n.toFixed(precision)).toFixed()
  if (newNumber == '0' && !n.eq(0)) {
    return '< 0.000001'
  }
  return newNumber
}
