/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Web3Provider } from '@ethersproject/providers'
import { makeStyles } from '@material-ui/core/styles'
import { useWeb3React } from '@web3-react/core'
import { WalletTypes } from 'components/buttons'
import { TransactionModal } from 'components/modals/modal_transaction'
import { init } from 'contracts/thor/contract'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { BRIDGE, HISTORY, PROPOSAL, VOTING } from 'routes'
import { injectedConnector } from 'services/Provider'
import { colors } from 'utils/mui'
import { StoreContext } from 'utils/store'
import { Header } from './header'

const useStyles = makeStyles({
  content: {
    backgroundColor: colors.globalBlack,
    height: 'calc(100vh - 72px)',
    width: '100%',
    color: colors.white,
  },
  contentWrap: {
    height: '100vh',
  },
})

export const ThorContext = createContext('')

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Layout = ({ children }: any) => {
  const {
    comet: [thorAccount, setThorAccount],
    metamask: [web3Account, setWeb3Account],
    thorSigner: [thorSigner, setThorSigner],
    haiBalance: [haiBalance, setHaiBalance],
    haiStaked: [haiStake, setHaiStake],
    transactionModal: [transactionModalOpen, setTransactionModalOpen],
    transactions: [transactions, updateTransactions, setTransactions],
  } = useContext(StoreContext)
  const { account, activate } = useWeb3React<Web3Provider>()
  const { content, contentWrap } = useStyles()
  const location = useLocation()
  useEffect(() => {
    setWeb3Account(account)
  }, [account])
  return (
    <div className={contentWrap}>
      <Header
        walletType={
          location.pathname === VOTING ||
          location.pathname.startsWith(PROPOSAL) ||
          location.pathname === HISTORY ||
          location.pathname === BRIDGE
            ? WalletTypes.EthWallet
            : WalletTypes.VetWallet
        }
        activateThorAccount={() => init(setThorAccount, setThorSigner, setHaiBalance, setHaiStake)}
        activateWeb3Account={() => activate(injectedConnector)}
        activeThorAccount={thorAccount ? thorAccount : ''}
        activeWeb3Account={web3Account ? web3Account : ''}
        transactions={transactions}
        openModal={setTransactionModalOpen}
      />
      <TransactionModal
        handleClose={() => setTransactionModalOpen(false)}
        open={transactionModalOpen}
        transactions={transactions}
        setTransactions={setTransactions}
      />
      <div className={content}>{children}</div>
    </div>
  )
}
