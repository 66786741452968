import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  modalBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.defaultBlue,
    borderRadius: 15,
    margin: 15,
  },
  addressBox: {
    borderRadius: '20px',
    padding: '0 20px',
    width: '-webkit-fill-available',
    margin: '10px',
    border: `2px solid ${colors.primaryGreen}`,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  addressText: {
    textTransform: 'uppercase',
    color: colors.primaryGreen,
    marginLeft: '10px',
    fontSize: '2.25rem',
    lineHeight: '44px',
    fontWeight: 700,
  },
})
