import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  buttonStyles: {
    textDecoration: 'none',
    textTransform: 'none',
    marginLeft: '12px',
    fontSize: '0.88rem',
    color: colors.walletButtonTextColor,
    width: '155px',
    lineHeight: '17px',
    borderRadius: '30px',
    borderColor: colors.primaryGrayTextColor,
    transition: 'all 0.4s',
    '&:hover': {
      opacity: 0.6,
    },
    '&:active': {
      opacity: 0.3,
    },
  },
})
