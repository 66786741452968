import { NETWORK_TYPE, SetInterface } from './index'

export const web3Sets: { [key in NETWORK_TYPE]?: SetInterface } = {
  Ropsten: {
    erc20Token: '0x18f04e1BFA5C0F28DB6AB07dA4E7b01cD40ed349',
    vptToken: '0xd3c325848d7c6e29b574cb0789998b2ff901f17e',
    voting: '0x3Da537aDa9c0014Aac97d7Ab1D9F8e77E8584876',
    bridge: '0x0c42A5428B7dff1Ec8f877adaB77De71b2F33bda',
  },
}

export const thorSets = {
  stacking: '0x94736D2270DB5587a79A71E6184B62D343eAdDc1',
  hai: '0xaCc280010B2EE0efc770BCE34774376656D8cE14',
}
