import { useEffect, useContext } from 'react'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { useRefresh } from './RefreshContext'
import { StoreContext } from 'utils/store'

export default function BlockUpdater(): null {
  const { fastRefresh } = useRefresh()
  const { library, chainId } = useWeb3React<Web3Provider>()
  const {
    blockInfo: [blockInfo, setBlockInfo],
  } = useContext(StoreContext)

  useEffect(() => {
    const newInfo = { ...blockInfo }
    if (library && chainId) {
      library.getBlockNumber().then((blockNumber) => {
        const infoIndex = newInfo.data.eth.findIndex(
          (el: { chainId: number; blockNumber: number }) => el.chainId === chainId,
        )
        if (infoIndex < 0) {
          newInfo.data.eth = [...newInfo.data.eth, { chainId, blockNumber }]
        } else {
          newInfo.data.eth[infoIndex] = { chainId, blockNumber }
        }
      })
    }
    if (library && window.connex) {
      const blockNumber = window.connex.thor.status.head.number
      newInfo.data.vechain = [{ chainId: 1, blockNumber }]
    }

    setBlockInfo(newInfo)
  }, [fastRefresh, chainId, library])
  return null
}
