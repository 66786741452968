import { Web3Provider } from '@ethersproject/providers'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import '@vechain/connex'
import { Web3ReactProvider } from '@web3-react/core'
import { client } from 'apollo/client'
import { CenteredContent } from 'components/center_content'
import { CustomCircularProgress } from 'components/custom_progress'
import { Layout } from 'components/layout'
import React, { lazy } from 'react'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BRIDGE } from 'routes'
import BlockUpdater from 'services/BlockUpdater'
import ConnectionManager from 'services/connectionManager/ConnectionManager'
import 'services/i18n'
import { RefreshContextProvider } from 'services/RefreshContext'
import TransactionUpdater from 'services/TransactionUpdater'
import { themeConfig } from 'utils/mui'

export const notifyAddress = () => toast.dark('toasts_address_text'.localized())
export const notifySync = () =>
  toast.dark('toasts_sync_text'.localized(), {
    autoClose: false,
  })
export const notifyBridgeBalance = () => toast.dark('toasts_bridge_balance_text'.localized())

//INFO: pages was disabled, we hope that they will be using in future
// const Staking = lazy(() => import('./pages/staking'))
// const Voting = lazy(() => import('./pages/voting'))
// const History = lazy(() => import('./pages/history'))
// const Proposals = lazy(() => import('./pages/active_proposals'))
// const Proposal = lazy(() => import('./pages/proposal'))
const Bridge = lazy(() => import('./pages/bridge'))

const App = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getLibrary = (provider: any): Web3Provider => {
    const library = new Web3Provider(provider)
    library.pollingInterval = 12000
    return library
  }

  const theme = createMuiTheme(themeConfig)
  return (
    <ApolloProvider client={client}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <RefreshContextProvider>
          <BlockUpdater />
          <TransactionUpdater />
          <MuiThemeProvider theme={theme}>
            <React.Suspense
              fallback={
                <CenteredContent>
                  <CustomCircularProgress />
                </CenteredContent>
              }
            >
              <Router>
                <ConnectionManager>
                  <Layout>
                    <Switch>
                      {/* <Route exact path={STAKING} component={Staking} />
                      <Route exact path={VOTING} component={Voting} />
                      <Route exact path={PROPOSALID} component={Proposal} />
                      <Route exact path={HISTORY} component={History} />
                      <Route exact path={PROPOSALS} component={Proposals} /> */}
                      <Route exact path={BRIDGE} component={Bridge} />
                      <Redirect to={BRIDGE} />
                    </Switch>
                  </Layout>
                </ConnectionManager>
              </Router>
            </React.Suspense>
          </MuiThemeProvider>
        </RefreshContextProvider>
      </Web3ReactProvider>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ApolloProvider>
  )
}

export default App
