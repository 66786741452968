import { thorSets } from 'contracts/sets/sets'
import { hai, staking } from './abi'

const _method = (address: string, method: any): Connex.Thor.Method => connex.thor.account(address).method(method)
const call = (address: string, method: any, params: any[]): Promise<Connex.Thor.VMOutput> =>
  _method(address, method).call(...params)
const clause = (address: string, method: any, params: any[]) => _method(address, method).asClause(...params)

export const init = (
  setAddress: React.Dispatch<React.SetStateAction<string>>,
  setSigner: React.Dispatch<React.SetStateAction<any>>,
  setHaiBalance: React.Dispatch<React.SetStateAction<string>>,
  setHaiStake: React.Dispatch<React.SetStateAction<string>>,
) =>
  (async () => {
    if (window.connex) {
      const { annex } = await window.connex.vendor.sign('cert').request({
        purpose: 'identification',
        payload: {
          type: 'text',
          content: 'Share address with Hacken Foundation App',
        },
      })
      await createSigner(annex.signer, setSigner)
      const balance = await balanceOf(annex.signer)

      await setHaiBalance(balance?.decoded?.[0])
      const staked = await stakedBalance(annex.signer)
      setHaiStake(staked?.decoded?.[0])
      await setAddress(annex.signer)
      return
    }
  })()

export const createSigner = (address: string, setSigner: React.Dispatch<React.SetStateAction<any>>) => {
  setSigner(connex?.vendor.sign('tx').signer(address))
}

export const balanceOf = async (address: string): Promise<Connex.Thor.VMOutput | undefined> => {
  return await call(thorSets.hai, hai.balanceOf, [address])
}

export const stakedBalance = async (address: string): Promise<Connex.Thor.VMOutput | undefined> => {
  return await call(thorSets.stacking, staking.staked, [address])
}

export const approve = async (
  price: string,
  signer: Connex.Vendor.TxSigningService,
  thorAccount: string,
  setHaiStake: React.Dispatch<React.SetStateAction<string>>,
) => {
  const staked = await stakedBalance(thorAccount)
  setHaiStake(staked?.decoded?.[0])
  const approve = clause(thorSets.hai, hai.approve, [thorSets.stacking, price.toString()])
  return await signer.request([{ comment: `approve ${price} HAI`, ...approve }])
}

export const stake = async (
  price: string,
  signer: Connex.Vendor.TxSigningService,
  thorAccount: string,
  setHaiStake: React.Dispatch<React.SetStateAction<string>>,
  address: string,
) => {
  const staked = await stakedBalance(thorAccount)
  setHaiStake(staked?.decoded?.[0])
  const deposit = clause(thorSets.stacking, staking.deposit, [address, price.toString()])
  return await signer.request([{ comment: `deposit ${price} HAI`, ...deposit }])
}

export const withdraw = async (
  signer: Connex.Vendor.TxSigningService,
  thorAccount: string,
  setHaiStake: React.Dispatch<React.SetStateAction<string>>,
  address: string,
) => {
  const staked = await stakedBalance(thorAccount)
  setHaiStake(staked?.decoded?.[0])
  const withdraw = clause(thorSets.stacking, staking.withdraw, [address, staked?.decoded?.[0]])
  return await signer.request([{ comment: `withdraw ${staked?.decoded?.[0]} HAI`, ...withdraw }])
}

export const allowance = async (
  address: string,
  setHaiAllowance: React.Dispatch<React.SetStateAction<any>>,
): Promise<Connex.Thor.VMOutput | undefined> => {
  const allowance = await call(thorSets.hai, hai.allowance, [address, thorSets.stacking])
  setHaiAllowance(allowance?.decoded?.[0])
  return await allowance
}
